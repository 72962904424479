export const getBase64FromPNG = ({ image, w, h}) => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const canvas = document.createElement('canvas')
      document.body.appendChild(canvas)
      if (image.endsWith('.svg')) {
        canvas.width = w
        canvas.height = h
      } else {
        w = img.width
        h = img.height
        canvas.width = w
        canvas.height = h
      }
      canvas.style.position = 'fixed'
      canvas.style.left = '-100vw'
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, w, h);
      const imgURI = canvas.toDataURL('image/png')
      document.body.removeChild(canvas)
      return resolve(imgURI)
    }

    img.onerror = (e) => {
      return reject(e)
    }

    img.src = image
  })
}

export const drawPNG = ({svg, flip, width = 286, height = 596}) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    document.body.appendChild(canvas)
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height
    canvas.style.position = 'fixed'

    const img = new Image()

    img.onload = function() {
      if (flip) {
        ctx.translate(width, 0)
        ctx.scale(-1, 1)
      }
      ctx.drawImage(img, 0, 0, width, height)
      const imgURI = canvas.toDataURL('image/png')
      document.body.removeChild(canvas)
      return resolve(imgURI)
    }

    img.onerror = (e) => {
      return reject(e)
    }

    img.src = svg
  })
}
