<template>
  <nav class="navbar">
    <a href="#"
       :class="['navbar__opener', {'navbar__opener--opened': expanded}]"
       @click.prevent="expanded = !expanded">Навігація</a>
    <ul :class="['navbar__list', {'navbar__list--opened': expanded}]">
      <li class="navbar__item"
          v-for="navigationItem in navigation"
          :key="navigationItem.to">
        <router-link :class="['navbar__link']"
                     :to="`${navigationItem.to}?code=${code}`">
          <img class="navbar__icon"
               :src="getNavbarIcon(navigationItem.img)" alt="">
          {{i18n.MENU[navigationItem.title]}}
        </router-link>
      </li>
    </ul>
    <div class="navbar__footer">
      <a href="#"
         @click.prevent="gotoPrev"
         class="navbar__prev">{{i18n.STEP_BACK}}</a>
      <a href="#"
         @click.prevent="gotoNext"
         class="navbar__next">{{i18n.STEP_NEXT}}</a>
    </div>
  </nav>
</template>

<script>
  import { NAV } from '@/constants/nav'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        nav: NAV,
        expanded: false,
      }
    },
    computed: {
      ...mapGetters('auth', [
        'isAuthenticated',
      ]),
      ...mapGetters('config', [
        'code',
        'house',
      ]),
      ...mapGetters('session', [
        'lang',
        'i18n',
      ]),
      navigation() {
        return this.nav.filter(n =>
          n.secure ?
            this.isAuthenticated :
            true)
      }
    },
    watch:{
      $route() {
        this.expanded = false;
      },
    },
    methods: {
      getNavbarIcon(pic) {
        return require('@/assets/images/navigation/' + pic)
      },
      goto({ idx, code }) {
        this.$router.push({
          path: this.navigation[idx].to,
          query: {
            code,
          }
        })
      },
      gotoPrev() {
        const code = this.$route.query.code
        const currentRoute = this.$router.currentRoute.path
        let currentRouteIdx = this.navigation.findIndex(r => r.to === currentRoute)

        if (this.navigation[currentRouteIdx]?.title === 'options') {
          this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'inside')
        }
        if (this.navigation[currentRouteIdx]?.title === 'colour' && this.house === 'inside') {
          this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'outside')
          return
        }

        if (currentRouteIdx > 0) {
          currentRouteIdx--
        } else {
          currentRouteIdx = this.navigation.length - 1
        }

        this.goto({ idx: currentRouteIdx, code })
      },
      gotoNext() {
        const code = this.$route.query.code
        const currentRoute = this.$router.currentRoute.path
        let currentRouteIdx = this.navigation.findIndex(r => r.to === currentRoute)

        if (this.navigation[currentRouteIdx]?.title === 'model') {
          this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'outside')
        }
        if (this.navigation[currentRouteIdx]?.title === 'colour' && this.house === 'outside') {
          this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'inside')
          return
        }

        if (currentRouteIdx < this.navigation.length - 1) {
          currentRouteIdx++
        } else {
          currentRouteIdx = 0
        }

        this.goto({ idx: currentRouteIdx, code })
      },
    },
  }
</script>

<style lang="scss">
  .navbar {
    position: relative;
    z-index: 4;
    &__opener {
      position: fixed;
      right: 0;
      top: 0;
      width: 60px;
      height: 50px;
      background: url(./sandwich.svg) 50%/20px 14px no-repeat;
      text-indent: -9999px;
      overflow: hidden;
      transition: background-color .25s;
      &--opened {
        background-color: #c70552;
      }
      @include miw($xl) {
        display: none;
      }
    }
    @include miw($xl) {
      position: relative;
      flex: 1 0 270px;
      max-width: 270px;
      display: flex;
      flex-direction: column;
      background: linear-gradient(243.32deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 75.62%), #272C30;
      background-blend-mode: overlay, normal;
      overflow-y: auto;
      z-index: 2;
    }
    &__list {
      position: fixed;
      left: 100vw;
      top: 50px;
      margin: 0;
      padding: 0;
      list-style: none;
      background: #272c30;
      width: 100vw;
      height: calc(100vh - 90px);
      transition: left .25s;
      overflow-y: auto;
      &--opened {
        left: 0;
      }
      @include miw($xl) {
        position: static;
        left: auto;
        top: auto;
        background: none;
        height: auto;
        width: auto;
        display: block;
        flex: 1;
      }
    }
    &__icon {
      display: none;
      @include miw($xl) {
        display: block;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &__link {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      padding: 15px 25px 15px 30px;
      min-height: 30px;
      font: 15px/18px 'ProximaNovaBold', sans-serif;
      color: #ffffff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      text: {
        decoration: none;
        transform: uppercase;
      }
      @include miw($xl) {
        padding-left: 75px;
        font-size: 16px;
        justify-content: flex-start;
      }
      &.router-link-exact-active {
        background-color: #C70552;
      }
      &:before {
        @include miw($xl) {
          content: "";
          position: absolute;
          right: 15px;
          top: 50%;
          width: 13px;
          height: 13px;
          transform: translate(0, -50%);
          background: url(./angle-right.svg) 0 0/13px 13px no-repeat;
        }
      }
    }
    &__footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      @include miw($xl) {
        position: static;
        left: auto;
        bottom: auto;
        width: auto;
        padding: 30px;
        justify-content: space-between;
      }
    }
    &__prev,
    &__next {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      text-decoration: none;
      font: 15px/18px 'ProximaNovaBold', sans-serif;
      flex: 1 0 50%;
      max-width: 50%;
      height: 40px;
      @include miw($xl) {
        max-width: 98px;
        flex: 1 0 98px;
        height: 46px;
        border-radius: 5px;
      }
      &[disabled="disabled"] {
        cursor: default;
        color: #7a848b;
        background-color: #40484e;
      }
    }
    &__prev {
      background-color: #ffffff;
      color: #34393d;
    }
    &__next {
      background-color: #c70552;
      color: #ffffff;
    }
  }

</style>
