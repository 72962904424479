<template>
  <div v-if="price !== ''" class="price-label">{{price}} {{currency.symbol}}</div>
</template>

<script>
  import axios from 'axios'
  import { API } from '@/constants/api'
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters('config', [
        'shape',
        'model',
        'glass',
        'color',
        'furniture',
        'options',
        'inox',
        'safeglass',
        'view',
        'black',
      ]),
      ...mapGetters('session', [
        'currencies',
        'currency',
      ]),
    },
    data() {
      return {
        price: '',
      }
    },
    methods: {
      getPrice() {
        const {
          shape,
          model,
          glass,
          color,
          furniture,
          options,
          inox,
          safeglass,
          view,
          black,
        } = this
        const moldingView = inox?.url.includes('molding') ? inox?.url.split('-')[1] : null
        axios
          .get(API.GET_PRICE, {
            params: {
              shape: shape.id,
              model: model.id,
              glass: glass.id,
              safeglass: safeglass.id,
              colour_outside: color.outside.id,
              colour_inside: color.inside.id,
              furniture: furniture.id,
              options: options.id,
              inox: inox.id,
              molding: moldingView,
              view: view.id,
              currency_name: this.currency.name,
              black,
            },
          })
          .then(response => (
            this.price = response.data.price?.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ")
          ));
      }
    },
    created() {
      this.getPrice()
    }
  }
</script>

<style lang="scss">
  .price-label {
    position: absolute;
    right: 20px;
    top: 75px;
    font: 26px/30px 'ProximaNovaBold', sans-serif;
    padding: 15px;
    color: #F8F8F8;
    background: rgba(39, 44, 48, 0.8);
    @include miw($xl) {
      top: 20px;
    }
  }
</style>
