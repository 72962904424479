<template>
  <overlay>
    <modal-window class="news-popup">
      <a href="#"
         @click.prevent="$emit('close')"
         class="modal-window__close">x</a>
      <div class="news-popup__content">
        <h2 class="news-popup__headline">{{ i18n.NEWS }}</h2>
        <news-list />
        <div class="news-popup__footer">
          <button class="btn" @click.prevent="$emit('close')">{{ i18n.CLOSE_POPUP }}</button>
        </div>
      </div>
    </modal-window>
  </overlay>
</template>
<script>
import NewsList from '@/components/news/list'
import Overlay from '@/components/shared/modal-overlay'
import ModalWindow from '@/components/shared/modal-window'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  components: {
    NewsList,
    Overlay,
    ModalWindow,
  }
}
</script>

<style lang="scss">
.news-popup {
  width: 100%;
  max-width: 960px;
  margin: auto;
  color: #525252;
  &__headline {
    margin: 0;
    padding: 10px 0;
    color: #ffffff;
    font: 16px/20px 'ProximaNovaBold', sans-serif;
    text-transform: uppercase;
  }
  &__footer {
    margin: 70px 0 0;
    .btn {
      display: inline-block;
      width: auto;
    }
  }
}
</style>
