<template>
  <g id="Left-Frame"
     v-if="doorLeftWidth !== 0">
    <rect id="Left-Frame"
          class="left-frame"
          x="0"
          :style="`fill: ${fill}`"
          :y="doorTopHeight"
          :width="doorLeftWidth"
          :height="doorHeight" />
    <rect id="Left-Glass"
          class="glass"
          filter="url(#inset-shadow)"
          :x="glassOffset"
          :y="glassY"
          :width="glassWidth"
          :height="glassHeight" />
  </g>
</template>

<script>
export default {
  props: [
    'doorHeight',
    'doorTopHeight',
    'doorLeftWidth',
    'fill',
  ],
  computed: {
    glassY() {
      return this.doorTopHeight + this.glassOffset
    },
    glassWidth() {
      return this.doorLeftWidth - this.glassOffset * 2;
    },
    glassHeight() {
      return this.doorHeight - this.glassOffset * 2
    },
  },
  data() {
    return {
      glassOffset: 7.5
    }
  },
}
</script>
