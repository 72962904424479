<template>
  <g id="Right-Frame"
     v-if="doorRightWidth !== 0">
    <rect id="Right-Frame"
          class="right-frame"
          :x="doorRightX"
          :style="`fill: ${fill}`"
          :y="doorTopHeight"
          :width="doorRightWidth"
          :height="doorHeight" />
    <rect id="Right-Glass"
          class="glass"
          filter="url(#inset-shadow)"
          :x="glassX"
          :y="glassY"
          :width="glassWidth"
          :height="glassHeight" />
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorTopHeight',
    'doorLeftWidth',
    'doorRightWidth',
    'fill',
  ],
  computed: {
    doorRightX() {
      return this.doorLeftWidth + this.doorWidth
    },
    glassX() {
      return this.doorRightX + this.glassOffset
    },
    glassY() {
      return this.doorTopHeight + this.glassOffset
    },
    glassWidth() {
      return this.doorRightWidth - this.glassOffset * 2;
    },
    glassHeight() {
      return this.doorHeight - this.glassOffset * 2
    },
  },
  data() {
    return {
      glassOffset: 7.5
    }
  },
}
</script>
