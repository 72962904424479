<template>
  <div class="top-tools">
    <nav class="lang" v-if="currencies.length > 1">
      <a href="#"
         v-if="currencies.length > 1"
         class="top-tools__item top-tools__item--curr"
         @click.prevent="showCurr = true">
        {{ currency.symbol }}
      </a>
      <ul class="lang__popup" v-if="showCurr">
        <li class="lang__item"
            :key="currencyItem.name"
            v-for="currencyItem in currencies">
          <a href="#"
             @click.prevent="setCurr(currencyItem)"
             class="lang__link">{{currencyItem.symbol}}</a>
        </li>
      </ul>
    </nav>
    <nav class="lang">
      <a href="#"
         class="top-tools__item top-tools__item--lang"
         @click.prevent="showLang = true">
        {{languageTitle}}
      </a>
      <ul class="lang__popup" v-if="showLang">
        <li class="lang__item"
            :key="l.LANG_TITLE"
            v-for="(l, k) in locales">
          <span v-if="k === lang"
                class="lang__link lang__link--current">{{l.LANG_TITLE}}</span>
          <a href="#"
             v-else
             @click.prevent="setLang(k)"
             class="lang__link">{{l.LANG_TITLE}}</a>
        </li>
      </ul>
    </nav>
    <a href="#"
       :class="['top-tools__item', {'top-tools__item--active': share}]"
       @click.prevent="$emit('share')">
      <img class="top-tools__icon top-tools__icon--mobile"
           src="./share-active.svg" alt="">
      <img v-show="!share"
           class="top-tools__icon top-tools__icon--desktop"
           src="./share.svg" alt="">
      <img v-show="share"
           class="top-tools__icon top-tools__icon--desktop"
           src="./share-active.svg" alt="">
    </a>
    <a href="#"
       :class="['top-tools__item', {'top-tools__item--active': question}]"
       @click.prevent="$emit('question')">
      <img class="top-tools__icon top-tools__icon--mobile"
           width="32"
           height="32"
           src="./question-active.svg" alt="">
      <img v-show="!question"
           width="32"
           height="32"
           class="top-tools__icon top-tools__icon--desktop"
           src="./question.svg" alt="">
      <img v-show="question"
           width="32"
           height="32"
           class="top-tools__icon top-tools__icon--desktop"
           src="./question-active.svg" alt="">
    </a>
    <router-link to="/my-door"
                 v-if="isAuthenticated"
                 :class="['top-tools__item', {'top-tools__item--active': fav}]"
                 @click.prevent="$router.push('MyDoor')">
      <img class="top-tools__icon top-tools__icon--mobile"
           src="./fav-active.svg" alt="">
      <img v-show="!fav"
           class="top-tools__icon top-tools__icon--desktop"
           src="./fav.svg" alt="">
      <img v-show="fav"
           class="top-tools__icon top-tools__icon--desktop"
           src="./fav-active.svg" alt="">
    </router-link>
    <a href="#"
       :class="['top-tools__item', {'top-tools__item--active': load}]"
       @click.prevent="$emit('load')">
      <img class="top-tools__icon top-tools__icon--mobile"
           src="./open-active.svg" alt="">
      <img v-show="!load"
           class="top-tools__icon top-tools__icon--desktop"
           src="./open.svg" alt="">
      <img v-show="load"
           class="top-tools__icon top-tools__icon--desktop"
           src="./open-active.svg" alt="">
    </a>
    <a href="#"
       class="top-tools__item top-tools__item--mobile"
       @click.prevent="$emit('flip')">
      <img class="top-tools__icon top-tools__icon--mobile"
           src="./flip.svg" alt="">
    </a>
    <router-link
       to="/login"
       v-if="!isAuthenticated"
       class="top-tools__item">
      <img class="top-tools__icon top-tools__icon--mobile"
           src="./login-active.svg" alt="">
      <img class="top-tools__icon top-tools__icon--desktop"
           src="./login.svg" alt="">
    </router-link>
    <a href="#"
       v-if="isAuthenticated"
       class="top-tools__item"
       @click.prevent="logout">
      <img class="top-tools__icon top-tools__icon--mobile"
           src="./logout-active.svg" alt="">
      <img class="top-tools__icon top-tools__icon--desktop"
           src="./logout.svg" alt="">
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import l12n from '@/constants/l12n'
import axios from 'axios'
import { API } from '@/constants/api'
import { CURRENCY_CONFIG, CURRENCY_STORAGE_KEY } from '@/constants/currency'
import { LANGUAGE_CONFIG } from '@/constants/language'

export default {
  props: ['load', 'share', 'question'],
  data() {
    return {
      showCurr: false,
      showLang: false,
      locales: Object.keys(l12n)
        .filter(key => LANGUAGE_CONFIG.includes(key))
        .reduce((obj, key) => {
          obj[key] = l12n[key]
          return obj
        }, {}),
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapGetters('session', [
      'lang',
      'i18n',
      'currency',
      'currencies',
    ]),
    fav() {
      return this.$route.name === 'MyDoor'
    },
    languageTitle() {
      return this.locales[this.lang]?.LANG_TITLE
    },
  },
  methods: {
    async setCurr(c) {
      this.curr = c
      await this.$store.dispatch('session/SET_CURRENCY', c)
      location.reload()
    },
    async setLang(k) {
      await this.$store.dispatch('session/SET_LANGUAGE', k)
      location.reload()
    },
    logout() {
      this.$store.dispatch('auth/AUTH_LOGOUT')
      location.reload()
    },
    clicker(e) {
      const target = e.target
      if (!target) {
        return
      }
      if (!target.classList.contains('top-tools__item--lang')) {
        this.showLang = false
      }
      if (!target.classList.contains('top-tools__item--curr')) {
        this.showCurr = false
      }
    },
    setDefaultCurrency() {
      let defaultCurr
      try {
        defaultCurr = JSON.parse(localStorage.getItem(CURRENCY_STORAGE_KEY))
      } catch (e) {
        console.error(e)
      }
      if (!defaultCurr) {
        defaultCurr = this.currencies[0]
      }
      const idx = this.currencies.findIndex(i => i.name === defaultCurr.name)
      if (idx !== -1) {
        this.$store.dispatch('session/SET_CURRENCY', this.currencies[idx])
      }
    },
  },
  async beforeCreate() {
    let res
    try {
      res = await axios.get(API.GET_CURRENCIES)
    } catch (e) {
      console.error(e)
    }

    if (res?.data.length) {
      let cs
      if (CURRENCY_CONFIG.length) {
        cs = res.data.filter(c => CURRENCY_CONFIG.includes(c.name))
      } else {
        cs = res.data
      }
      if (cs.length) {
        await this.$store.dispatch('session/SET_CURRENCIES', cs)
      }
      this.setDefaultCurrency()
    }
  },
  mounted() {
    document.addEventListener('click', (e) => this.clicker(e))
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clicker)
  },
}
</script>

<style lang="scss">
.lang {
  position: relative;
  &__popup {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #C70552;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
  }
  &__item {
    display: block;
    font: 20px/90px 'proxima_nova_rgregular', sans-serif;
  }
  &__link {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    transition: background-color .25s linear;
    &--current {
      cursor: default;
      background-color: #272c30;
    }
    @include miw($xl) {
      &:hover {
        background-color: #272c30;
      }
    }
  }
}
.top-tools {
  position: absolute;
  left: 25px;
  top: 40vw;
  transform: translate(0, -50%);
  background-color: #ffffff;
  z-index: 3;
  @include miw($xl) {
    display: flex;
    left: auto;
    right: 0;
    top: 0;
    transform: none;
  }
  &__item {
    display: flex;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    background: #272c30;
    @include miw($xl) {
      margin-top: 0;
      width: 90px;
      height: 90px;
      background: none;
      border-left: 1px solid #EDEDED;
      &--mobile {
        display: none;
      }
    }
    &--active {
      background-color: #c70552;
    }
    &:first-child {
      border-left-width: 0;
    }
    &--lang,
    &--curr {
      text-decoration: none;
      font: 20px/90px 'proxima_nova_rgregular', sans-serif;
      border-left-width: 1px !important;
    }
    &--lang {
      color: #ffffff;
      font-size: 12px;
      @include miw($xl) {
        color: inherit;
        font-size: 20px;
      }
    }
  }
  &__icon {
    &--desktop {
      display: none;
      @include miw($xl) {
        display: block;
      }
    }
    &--mobile {
      width: 16px;
      height: 16px;
      @include miw($xl) {
        display: none;
      }
    }
  }
}
</style>
