export const NAV = [
  {
    to: '/model',
    img: 'construction-and-tools.svg',
    title: 'model',
  },
  {
    to: '/color',
    img: 'color.svg',
    title: 'colour',
  },
  {
    to: '/options',
    img: 'options.svg',
    title: 'options',
  },
  {
    to: '/glass',
    img: 'glass.svg',
    title: 'glass'
  },
  {
    to: '/furniture',
    img: 'furniture.svg',
    title: 'furniture',
  },
  {
    to: '/shape',
    img: 'shape.svg',
    title: 'shape',
  },
  // {
  //   to: '/house',
  //   img: 'house.svg',
  //   title: 'house',
  // },
  {
    to: '/config',
    img: 'config.svg',
    title: 'config',
  },
  {
    to: '/order',
    img: 'order.svg',
    title: 'order',
    secure: true,
  },
  {
    to: '/my-door',
    img: 'my-door.svg',
    title: 'my',
    secure: true,
  },
]
