<template>
  <div class="lockdown" ref="ld" :style="styles">
    <div v-for="banner in banners"
         :key="banner.id">
      <a v-if="banner.url"
         :href="banner.url">
        <img :src="banner.image"
             v-if="banner.image"
             alt="">
        <div v-html="banner.text"
             v-if="banner.text"></div>
      </a>
      <div v-else>
        <img :src="banner.image"
             v-if="banner.image"
             alt="">
        <div v-html="banner.text"
             v-if="banner.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { API } from '@/constants/api'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      banners: '',
    }
  },
  computed: {
    ...mapGetters('session', [
      'lang',
    ]),
    styles() {
      return this.banners.length ?
        '' :
        'min-height: 26px'
    }
  },
  async mounted() {
    const header = await document.querySelector('header');
    const navbar = await document.querySelector('nav.navbar');
    const navbarOpener = await document.querySelector('a.navbar__opener');
    if (window.getComputedStyle(navbarOpener).display !== 'none') {
      setTimeout(() => {
        const hh = this.$refs.ld.clientHeight;
        header.style.top = hh + 'px';
        navbar.style.marginTop = hh + 'px';
        navbarOpener.style.top = hh + 'px';
      }, 300);
    }
  },
  beforeCreate() {
    axios
      .get(API.GET_BANNER, {
        headers: {
          'Accept-Language': this.lang
        }
      })
      .then(response => (this.banners = response.data));
  },
}
</script>

<style lang="scss">
.lockdown {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #c70552;
  color: white;
  text-align: center;
  padding: 5px;
  z-index: 5;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  @include miw($xl) {
    position: static;
    left: auto;
    top: auto;
    background: #c70552;
    color: white;
    text-align: center;
    font: 15px/18px ProximaNovaBold, sans-serif;
    text-transform: uppercase;
    padding: 4px;
    min-height: 26px;
  }
}
</style>
