<template>
  <g>
    <!-- door frame BEGIN -->
    <rect id="frame"
          class="frame"
          :style="`fill: ${fill}`"
          :x="doorLeftWidth"
          :y="doorTopHeight"
          :width="doorWidth"
          :height="doorHeight" />
    <!-- door frame END -->

    <!-- door leaf BEGIN -->
    <rect filter="url(#doorleafshadow)"
          id="doorleaf"
          class="doorleaf"
          :style="`fill: ${fill}`"
          :x="leafX"
          :y="leafY"
          :width="leafWidth"
          :height="leafHeight" />
    <!-- door leaf END -->

    <!-- door bg BEGIN -->
    <rect filter="url(#BGshadow)"
          v-if="showBg"
          id="BG"
          :style="plainModel"
          class="doorbackground"
          :x="bgX"
          :y="bgY"
          :width="bgWidth"
          :height="bgHeight" />
    <!-- door bg END -->
  </g>
</template>
<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorTopHeight',
    'doorLeftWidth',
    'doorViewType',
    'showBg',
    'fill',
    'model',
  ],
  data() {
    return {
      leafOffset: 6.4,
      bgOffset: 22,
    }
  },
  computed: {
    leafX() {
      return this.doorLeftWidth + this.leafOffset
    },
    leafY() {
      return this.doorTopHeight + this.leafOffset
    },
    leafWidth() {
      return this.doorWidth - this.leafOffset * 2
    },
    leafHeight() {
      return this.doorHeight - this.leafOffset * 2
    },
    bgX() {
      return this.doorLeftWidth + this.bgOffset
    },
    bgY() {
      return this.doorTopHeight + this.bgOffset
    },
    bgWidth() {
      return this.doorWidth - this.bgOffset * 2
    },
    bgHeight() {
      return this.doorHeight - this.bgOffset * 2
    },
    isPlainModel() {
      return this.model === 'c16'
          || this.model === 'c17'
          || this.model === 'c17g'
          || this.model === 'c18'
          || this.model === 'c18g'
          || this.model === 'c19'
          || this.model === 'c19g'
          || this.model === 'c20'
          || this.model === 'c20g'
          || this.model === 'c21'
          || this.model === 'c21g'
          || this.model === 'c22'
          || this.model === 'c22g'
          || this.model === 'c23'
          || this.model === 'c23g'
    },
    plainModel() {
      if (this.isPlainModel) {
        return `fill: ${this.fill};filter: none;stroke: transparent;`
      }
      return `fill: ${this.fill}`
    }
  },
}
</script>
