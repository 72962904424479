import Vue from 'vue'
import Vuex from 'vuex'

import session from './modules/session'
import config from './modules/config'
import auth from './modules/auth'
import news from './modules/news'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    session,
    config,
    auth,
    news,
  },
  strict: debug,
});
