import axios from 'axios'
import { API } from '@/constants/api'

const actions = {
  ['GET_NEWS']: () => {
    return axios.get(API.GET_NEWS)
  },
}

export default {
  namespaced: true,
  actions,
}
