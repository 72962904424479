<template>
  <div class="view-buttons">
    <button
      :class="[
        'view-buttons__btn',
        { 'view-buttons__btn--current': this.house === 'outside' }
      ]"
      @click.prevent="
        $store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'outside')
      "
    >
      <img src="./outside.svg" class="view-buttons__icon" alt="" />
      {{ i18n.VIEW_OUTSIDE }}
    </button>
    <button
      :class="[
        'view-buttons__btn',
        { 'view-buttons__btn--current': this.house === 'inside' }
      ]"
      @click.prevent="$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'inside')"
    >
      <img src="./inside.svg" class="view-buttons__icon" alt="" />
      {{ i18n.VIEW_INSIDE }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('config', ['house']),
    ...mapGetters('session', ['i18n'])
  }
};
</script>

<style lang="scss">
.view-buttons {
  display: none;
  @include miw($xl) {
    position: fixed;
    display: flex;
    right: 30px;
    bottom: 30px;
    background-color: #272c30;
    z-index: 2;
  }
  &__btn {
    display: flex;
    width: 110px;
    flex: 1 0 102px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 0;
    font: 13px/100% 'ProximaNovaRegular', sans-serif;
    transition: background-color 0.25s;
    &:focus {
      outline: none;
    }
    &:hover,
    &--current {
      background-color: #c70552;
    }
  }
  &__icon {
    display: block;
    width: 34px;
    height: 34px;
    margin: 0 auto 5px;
  }
}
</style>
