const API_URL = `${process.env.VUE_APP_API_ORIGIN}${process.env.VUE_APP_API_ENDPOINT}`

export const API = {
  GET_SHAPE: `${API_URL}shape/`,
  GET_BANNER: `${API_URL}banner/`,
  GET_MODELS: `${API_URL}models/`,
  GET_MODEL_SERIES: `${API_URL}modelseries/`,
  GET_MODEL: (id) => `/uploads/MackoDoorModel/models/${id}.svg`,
  GET_GLASS: `${API_URL}glass/`,
  GET_FURNITURE: `${API_URL}furniture/`,
  GET_COLORS: `${API_URL}color/`,
  GET_PRICE: `${API_URL}price/`,
  GET_DEFAULT: `${API_URL}default/`,
  POST_ORDER: `${API_URL}order/`,
  GET_CONFIG_FROM_CODE: `${API_URL}decode/`,
  POST_QUESTION: `${API_URL}question/`,
  GET_CODE_FROM_CONFIG: `${API_URL}code/`,
  GET_PDF: `${API_URL}pdf/`,
  GET_OPTIONS: `${API_URL}options/`,
  GET_INOX: `${API_URL}inox/`,
  GET_VIEW: `${API_URL}view/`,
  GET_MIRRORED: `${API_URL}mirrored/`,
  GET_SAFE: `${API_URL}safeglass/`,
  AUTH: `${API_URL}login/`,
  USER_INFO: `${API_URL}userinfo/`,
  GET_DOORS: `${API_URL}doors/`,
  ADD_DOOR: `${API_URL}doors/`,
  DELETE_DOOR: `${API_URL}doors/`,
  GET_CURRENCIES: `${API_URL}currencies/`,
  GET_NEWS: `${API_URL}news/`,
}
