<template>
  <div class="copy-to-clipboard">
    <button class="copy-to-clipboard__btn"
            :title="i18n.SHARE.COPY"
            @click="copyToClipboard(code)">
      <img class="copy-to-clipboard__img"
           :src="require('@/assets/images/config/4.svg')">
    </button>
    <input class="copy-to-clipboard__text" disabled :value="formattedCode"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCode } from '@/utils/code'

export default {
  props: ['code'],
  computed: {
    ...mapGetters('session', [
      'i18n',
    ]),
    formattedCode() {
      return formatCode(this.code)
    }
  },
  methods: {
    copyToClipboard(configCode) {
      this.$copyText(configCode).then((e) => {
        this.$emit('success', e.text)
      }, () => {
        this.$emit('error', this.i18n.uk.CONFIG.CODE_SHARE_ERROR)
      });
    },
  },
}
</script>

<style lang="scss">
.copy-to-clipboard {
  background: #272C30;
  border-radius: 3px;
  font: 28px/34px 'ProximaNovaBold', sans-serif;
  color: #FFFFFF;
  height: 60px;
  width: 100%;
  display: flex;

  &__btn {
    cursor: pointer;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  &__img {
    display: inline-block;
    background: #C70552;
    border-radius: 3px;
    padding: 14px;
    margin: 5px;
  }

  &__text {
    flex: 1;
    background: none;
    color: #fff;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font: 18px/20px 'ProximaNovaBold', sans-serif;
  }
}
</style>
