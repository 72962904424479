export const setCode = async ({code, store, router}) => {
  if (code) {
    await store.dispatch('config/CONFIG_SET_CODE', code)
    try {
      await router.push({ query: { code }})
    } catch (e) {
      console.error(e)
    }
  } else {
    console.info('No code has been generated.')
  }
}
export const sanitizeCode = (code) => {
  if (code.indexOf('.') !== -1) {
    code = code.split('.').join('')
  }
  return code
}

export const formatCode = (code) => {
  const newCode = code
  return newCode.substr(0, 4) +
    '.' +
    newCode.substr(4, 4) +
    '.' +
    newCode.substr(8, 3) +
    '/' +
    newCode.substr(11, 1) +
    '-' +
    newCode.substr(12, 4) +
    '.' +
    newCode.substr(16, newCode.length)
}
