<template>
  <overlay>
    <modal-window>
      <a href="#"
         @click.prevent="$emit('close')"
         class="modal-window__close">x</a>
      <div class="row">
        <div class="col">
          <div class="hint">{{i18n.SHARE.CODE}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <copy-to-clipboard :code="code"
                             @success="showCopyResult"
                             @error="showCopyResult" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <copy-to-clipboard-button :code="code"
                                    @success="showCopyResult"
                                    @error="showCopyResult" />
        </div>
      </div>
      <transition name="fade" @enter="animationEnter">
        <div class="row" v-show="codeMessage">
          <div class="col">
            <div class="hint">{{i18n.SHARE.CODE_COPIED}}</div>
          </div>
        </div>
      </transition>
    </modal-window>
  </overlay>
</template>

<script>
import Overlay from '@/components/shared/modal-overlay'
import ModalWindow from '@/components/shared/modal-window'
import CopyToClipboard from '@/components/shared/copy/copy-to-clipboard'
import CopyToClipboardButton from '@/components/shared/copy/copy-to-clipboard-button'
import { mapGetters } from 'vuex'

export default {
  components: {
    Overlay,
    ModalWindow,
    CopyToClipboard,
    CopyToClipboardButton,
  },
  computed: {
    ...mapGetters('config', [
      'code',
    ]),
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  data() {
    return {
      codeMessage: '',
    }
  },
  methods: {
    animationEnter() {
      setTimeout(() => {
        this.codeMessage = ''
      }, 3000)
    },
    showCopyResult(text) {
      this.codeMessage = text
    }
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
