export default {
  'uk-UA': {
    LANG_TITLE: 'Укр',
    INFO_TITLE: 'Інформація',
    ERROR_TITLE: 'Помилка',
    STEP_NEXT: 'далі',
    STEP_BACK: 'назад',
    VIEW_OUTSIDE: 'ззовні',
    VIEW_INSIDE: 'зсередини',
    SELECT_DOOR_SERIES: 'Оберіть серію дверей',
    NEWS: 'Новини',
    CLOSE_POPUP: 'Закрити',
    MODEL: {
      NO_MODELS: 'Немає моделей, що підходять.'
    },
    VALIDATE: {
      REQUIRED: 'Будь-ласка, заповніть поле.',
      EMAIL: 'Невірний формат e-mail.',
      BETWEEN: 'Від ${min_value} до ${max_value}',
      FILE_SIZE: 'Розмір файлу не повинен перевищувати ${max_size} Мб',
      FILE_EXT: 'Розширення файлу не повинно бути ${ext}'
    },
    AUTH: {
      LOGIN: 'Увійти',
      EMAIL: 'E-mail',
      PASSWORD: 'Пароль'
    },
    OPTIONS: {
      CONSTRUCTION: 'Конструкція',
      EMPTY_CONSTRUCTION: 'Немає конструкцій.',
      DIRECTION: 'Напрямок відкривання',
      SIZES: 'Розміри',
      SIZES_SELECT: 'Вкажіть габарит дверей по рамі',
      W: 'Ш',
      H: 'В',
      L: 'Л',
      R: 'П',
      MM: 'мм',
      FRAME: 'Фрамуга',
      HEIGHT: 'Висота',
      DESCRIPTION: `Максимальний габарит панелі:<br/>
        896х2146 мм - HPL i PVC<br/>
        1200х2300 мм - All алюміній<br/>
        Максимальний розмір дверей обмежується габаритом панелі + ширина профільної системи.`,
      MODEL_VIEW: 'Вигляд моделі',
      INOX: 'Декор',
      SAFE_GLASS: 'Безпечне скло'
    },
    MENU: {
      model: 'Модель',
      colour: 'Колір',
      options: 'Опції',
      glass: 'Скло',
      furniture: 'Фурнітура',
      shape: 'Форма дверей',
      house: 'Мій будинок',
      config: 'Конфігурація',
      order: 'Замовити',
      my: 'Мої двері'
    },
    MY: {
      NO_DOORS: 'Ще не створено жодної моделі.'
    },
    CONFIGURATOR: 'конфігуратор',
    COLOUR: {
      NO_COLORS: 'Немає кольорів, що підходять.',
      BLACK: 'Чорний дизайн',
      TABS: ['HPL', 'Алюмінієвий лист']
    },
    FURNITURE: {
      SELECT_MODEL: 'Спочатку оберіть бажану модель.',
      EMPTY_FURNITURE: 'Немає фурнітури, що підходить.'
    },
    SHARE: {
      COPY: 'Копіювати',
      COPY_LINK: 'скопіювати посилання',
      CODE: 'Код конфігурації',
      CODE_COPIED: 'Код конфігурації скопійовано'
    },
    LOAD: {
      CODE: 'Код конфігурації',
      CANCEL: 'відмінити',
      LOAD: 'завантажити',
      ERROR: 'Код конфігурації не знайдено'
    },
    QUESTION: {
      PHONE: 'Ваш номер тел. для звʼязку',
      PLACEHOLDER: 'Задати питання',
      BTN_CANCEL: 'Відміна',
      BTN_SUBMIT: 'Задати',
      ERROR: 'Помилка',
      THANKS: 'Дякуємо за Ваше питання'
    },
    ORDER: {
      NAME: "Ім'я",
      EDRPOU: 'Код ЄДРПОУ',
      PHONE: 'Телефон',
      ADDRESS: 'Пошта',
      CITY: 'Адреса доставки «Новою поштою»',
      ADDITIONAL: 'Додаткова інформація',
      ADD_FILE: 'Додати файл',
      CHOOSE_FILE: 'Вибрати файл',
      SELECT_SIZE: 'Вкажіть габаритний розмір панелі (ззовні)',
      SELECT_BOTH_SIZE:
        'Двосторонньо накладна панель <br/>Розмір внутрішнього листа',
      SELECT_WING: 'Односторонньо накладна панель',
      SELECT_WING_SIZE: 'Розмір крила',
      W: 'Ш',
      H: 'В',
      MM: 'мм',
      DESCRIPTION: ({ modelName, modelWidth, modelLength }) =>
        `Стандартний розмір панелі 900х2150 мм, мінімальний розмір моделі ${modelName}: ширина ${modelWidth}, висота: ${modelLength}.`,
      MAX_SIZE: `Максимальний габарит панелі:<br/>
        896 х 2146 мм - HPL i PVC<br/>
        1200 х 2300 мм - All алюміній.`,
      COMPLETE: 'Комплект для вклейки панелі',
      CHECKOUT: 'Замовити'
    },
    SHAPE: {
      EMPTY_SHAPES:
        'Не знайдено жодної форми. Спробуйте перезавантажити сторінку.'
    },
    GLASS: {
      SELECT_MODEL: 'Спочатку оберіть бажану модель.',
      NO_GLASSES: 'Не знайдено варіантів які підходять.'
    },
    CONFIG: {
      META: {
        TITLE: 'Конфігурація',
        DESCRIPTION: 'Конфігурація',
        OG_TITLE: 'Конфігурація',
        OG_DESCRIPTION: 'Конфігурація'
      },
      TITLE: 'Конфігурація',
      SAVE: 'Зберегти конфігурацію',
      DOWNLOAD: 'Скачати специфікацію',
      CODE_SHARE: code =>
        `Код ${code} копійовано. Надішліть його нашому менеджеру для того, щоб порахувати вартість.`,
      CODE_SHARE_ERROR:
        'Помилка копіювання. Запишіть будь-ласка код і повідомте нам телефоном',
      ADDED_TO_MY_DOORS: 'Додано до списку "Мої двері"',
      TEXT: `<p>Надішліть код конфігурації Ваших дверей продавцю чи виробнику дверей.</p>
        <p>Даний конфігуратор визначає лише вартість заповнення дверей MACKO.</p>
        <p>Вартість всієї конструкції — сумма вартості дверної конструкції без заповнення, та вартості дверного заповнення МАСКО.</p>`
    },
    NEWS_POPUP: {
      MORE: 'дізнатися більше'
    }
  },
  'ru-RU': {
    LANG_TITLE: 'Рус',
    INFO_TITLE: 'Информация',
    ERROR_TITLE: 'Ошибка',
    STEP_NEXT: 'вперед',
    STEP_BACK: 'назад',
    VIEW_OUTSIDE: 'снаружи',
    VIEW_INSIDE: 'внутри',
    SELECT_DOOR_SERIES: 'Выберите серию дверей',
    NEWS: 'Новости',
    CLOSE_POPUP: 'Закрыть',
    MODEL: {
      NO_MODELS: 'Нет подходящих моделей.'
    },
    VALIDATE: {
      REQUIRED: 'Поле не должно быть пустым.',
      EMAIL: 'Неправильный адрес e-mail.',
      BETWEEN: 'От ${min_value} до ${max_value}',
      FILE_SIZE: 'Размер файла не должен превышать ${max_size} Мб',
      FILE_EXT: 'Некорректное расширение файла ${ext}'
    },
    AUTH: {
      LOGIN: 'Войти',
      EMAIL: 'E-mail',
      PASSWORD: 'Пароль'
    },
    OPTIONS: {
      CONSTRUCTION: 'Конструкция',
      EMPTY_CONSTRUCTION: 'Нет конструкций.',
      DIRECTION: 'Направления открытия',
      SIZES: 'Размеры',
      SIZES_SELECT: 'Укажите габарит дверей по раме',
      W: 'Ш',
      H: 'В',
      L: 'Л',
      R: 'П',
      MM: 'мм',
      FRAME: 'Фрамуга',
      HEIGHT: 'Высота',
      DESCRIPTION: `Максимальный габарит панели:<br/>
        896х2146 мм - HPL и PVC<br/>
        1200х2300 мм - All алюминий<br/>
        Максимальный размер двери ограничивается габаритом модели + шириной профильной системы.`,
      MODEL_VIEW: 'Вид модели',
      INOX: 'Декор',
      SAFE_GLASS: 'Безопасное стекло'
    },
    MENU: {
      model: 'Модель',
      colour: 'Цвет',
      options: 'Опции',
      glass: 'Стекло',
      furniture: 'Фурнитура',
      shape: 'Форма дверей',
      house: 'Мой дом',
      config: 'Конфигурация',
      order: 'Заказать',
      my: 'Мои двери'
    },
    MY: {
      NO_DOORS: 'Вы еще не создали ни одной модели.'
    },
    CONFIGURATOR: 'конфигуратор',
    COLOUR: {
      NO_COLORS: 'Нет подходящих цветов.',
      BLACK: 'Черный дизайн',
      TABS: ['HPL', 'Алюминиевый лист']
    },
    FURNITURE: {
      SELECT_MODEL: 'Сначала выберите модель.',
      EMPTY_FURNITURE: 'Нет подходящей фурнитуры.'
    },
    SHARE: {
      COPY: 'Копировать',
      COPY_LINK: 'копировать ссылку',
      CODE: 'Код конфигурации',
      CODE_COPIED: 'Код конфигурации скопирован'
    },
    LOAD: {
      CODE: 'Код конфигурации',
      CANCEL: 'отменить',
      LOAD: 'загрузить',
      ERROR: 'Код конфигурации не найден'
    },
    QUESTION: {
      PHONE: 'Ваш номер тел. для связи',
      PLACEHOLDER: 'Задать вопрос',
      BTN_CANCEL: 'Отмена',
      BTN_SUBMIT: 'Спросить',
      ERROR: 'Ошибка',
      THANKS: 'Спасибо за Ваш вопрос'
    },
    ORDER: {
      NAME: 'Имя',
      EDRPOU: 'Код ЕГРПОУ (если есть)',
      PHONE: 'Телефон',
      ADDRESS: 'Почта',
      CITY: 'Адрес доставки «Новой почтой»',
      ADDITIONAL: 'Дополнительная информация',
      ADD_FILE: 'Добавить файл',
      CHOOSE_FILE: 'Выбрать файл',
      SELECT_SIZE: 'Укажите габаритные размеры панели (снаружи)',
      SELECT_BOTH_SIZE:
        'Двухсторонняя накладная панель <br/>Размер внутреннего листа',
      SELECT_WING: 'Односторонняя накладная панель',
      SELECT_WING_SIZE: 'Размер крыла',
      W: 'Ш',
      H: 'В',
      MM: 'мм',
      DESCRIPTION: ({ modelName, modelWidth, modelLength }) =>
        `Стандартный размер панели 900х2150 мм, минимальный размер модели ${modelName}: ширина ${modelWidth}, высота: ${modelLength}.`,
      MAX_SIZE: `Максимальный габарит панели:<br/>
        896 х 2146 мм - HPL и PVC<br/>
        1200 х 2300 мм - All алюминий.`,
      COMPLETE: 'Комплект для вклейки панели',
      CHECKOUT: 'Заказать'
    },
    SHAPE: {
      EMPTY_SHAPES:
        'Не найдено ни одной формы. Попробуйте перезагрузить страницу.'
    },
    GLASS: {
      SELECT_MODEL: 'Сначала выберите нужную модель.',
      NO_GLASSES: 'Нет подходящих вариантов.'
    },
    CONFIG: {
      META: {
        TITLE: 'Конфигурация',
        DESCRIPTION: 'Конфигурация',
        OG_TITLE: 'Конфигурация',
        OG_DESCRIPTION: 'Конфигурация'
      },
      TITLE: 'Конфигурация',
      SAVE: 'Сохранить конфигурацию',
      DOWNLOAD: 'Скачать спецификацию',
      CODE_SHARE: code =>
        `Код ${code} скопирован. Отправте его нашему менеджеру для просчета стоимости.`,
      CODE_SHARE_ERROR:
        'Ошибка копирования. Запишите, пожалуйста, код и передайте нашему менеджеру по телефону.',
      ADDED_TO_MY_DOORS: 'Добавлено в список "Мои двери"',
      TEXT: `<p>Отправьте код конфигурации Ваших дверей дилеру или производителю.</p>
        <p>Этот конфигуратор определяет только стоимость заполнения дверей MACKO.</p>
        <p>Стоимость всей конструкции — сумма стоимости дверной конструкции без заполнения и стоимость дверного заполнения МАСКО.</p>`
    },
    NEWS_POPUP: {
      MORE: 'узнать больше'
    }
  },
  'en-US': {
    LANG_TITLE: 'Eng',
    INFO_TITLE: 'Information',
    ERROR_TITLE: 'Error',
    STEP_NEXT: 'next',
    STEP_BACK: 'back',
    VIEW_OUTSIDE: 'outside',
    VIEW_INSIDE: 'inside',
    SELECT_DOOR_SERIES: 'Select door series',
    NEWS: 'News',
    CLOSE_POPUP: 'Close',
    MODEL: {
      NO_MODELS: 'No models in this series.'
    },
    VALIDATE: {
      REQUIRED: 'Field should not be empty.',
      EMAIL: 'Invalid e-mail.',
      BETWEEN: 'From ${min_value} to ${max_value}',
      FILE_SIZE: 'File size should not exceed ${max_size} Mb',
      FILE_EXT: 'Invalid file extension ${ext}'
    },
    AUTH: {
      LOGIN: 'Login',
      EMAIL: 'E-mail',
      PASSWORD: 'Password'
    },
    OPTIONS: {
      CONSTRUCTION: 'Construction',
      EMPTY_CONSTRUCTION: 'No constructions.',
      DIRECTION: 'Direction',
      SIZES: 'Sizes',
      SIZES_SELECT: 'Select door size',
      W: 'W',
      H: 'H',
      L: 'L',
      R: 'R',
      MM: 'mm',
      FRAME: 'Frame',
      HEIGHT: 'Height',
      DESCRIPTION: `Maximum panel size:<br/>
        896х2146 mm - HPL and PVC<br/>
        1200х2300 mm - All aluminium<br/>
        Maximal door size is limited by panel size + profile system width.`,
      MODEL_VIEW: 'Model view',
      INOX: 'Decor',
      SAFE_GLASS: 'Safe glass'
    },
    MENU: {
      model: 'Model',
      colour: 'Colour',
      options: 'Options',
      glass: 'Glass',
      furniture: 'Furniture',
      shape: 'Shape',
      house: 'My house',
      config: 'Configuration',
      order: 'Order',
      my: 'My doors'
    },
    MY: {
      NO_DOORS:
        'You haven\'t added any doors yet. Go to "CONFIGURATION" page and click on "SAVE CONFIGURATION".'
    },
    CONFIGURATOR: 'configurator',
    COLOUR: {
      NO_COLORS: 'No colours found.',
      BLACK: 'Black design',
      TABS: ['HPL', 'Aluminium RAL']
    },
    FURNITURE: {
      SELECT_MODEL: 'Select model first.',
      EMPTY_FURNITURE: 'No furniture found.'
    },
    SHARE: {
      COPY: 'Copy',
      COPY_LINK: 'copy link',
      CODE: 'Configuration code',
      CODE_COPIED: 'Configuration code copied'
    },
    LOAD: {
      CODE: 'Configuration code',
      CANCEL: 'cancel',
      LOAD: 'load',
      ERROR: 'Configuration code not found'
    },
    QUESTION: {
      PHONE: 'Your phone number for feedback',
      PLACEHOLDER: 'Ask a question',
      BTN_CANCEL: 'Cancel',
      BTN_SUBMIT: 'Ask',
      ERROR: 'Error',
      THANKS: 'Thank you for your question'
    },
    ORDER: {
      NAME: 'Name',
      EDRPOU: 'NSRUEO code (if exists)',
      PHONE: 'Phone',
      ADDRESS: 'Address',
      CITY: '"Nova Post" branch address',
      ADDITIONAL: 'Additional information',
      ADD_FILE: 'Add file',
      CHOOSE_FILE: 'Choose file',
      SELECT_SIZE: 'Select panel size (outside)',
      SELECT_BOTH_SIZE: 'Two sides panel <br/>Inner size',
      SELECT_WING: 'One side panel',
      SELECT_WING_SIZE: 'Wing size',
      W: 'W',
      H: 'H',
      MM: 'mm',
      DESCRIPTION: ({ modelName, modelWidth, modelLength }) =>
        `A panel default size is 900х2150 mm, Minimal model's size ${modelName}: width ${modelWidth}, length: ${modelLength}.`,
      MAX_SIZE: `Max size of a panel:<br/>
        896 х 2146 mm - HPL and PVC<br/>
        1200 х 2300 mm - All aluminium.`,
      COMPLETE: 'Panel setup kit',
      CHECKOUT: 'Order'
    },
    SHAPE: {
      EMPTY_SHAPES: 'No shapes found. Try to refresh the page.'
    },
    GLASS: {
      SELECT_MODEL: 'Select a model first.',
      NO_GLASSES: 'No glasses.'
    },
    CONFIG: {
      META: {
        TITLE: 'Configuration',
        DESCRIPTION: 'Configuration',
        OG_TITLE: 'Configuration',
        OG_DESCRIPTION: 'Configuration'
      },
      TITLE: 'Order',
      SAVE: 'Save configuration',
      DOWNLOAD: 'Download specs',
      CODE_SHARE: code =>
        `Code ${code} is copied to the clipboard. Just send it to our manager to calculate a price.`,
      CODE_SHARE_ERROR:
        'Code can not be copied for some reason. Please remember it and share with our manager directly',
      ADDED_TO_MY_DOORS: 'Added to "My doors"',
      TEXT: `<p>Share door configuration code with a dealer or manufacturer.</p>
        <p>This configurator can calculate only a price depending on a selected MACKO door fill.</p>
        <p>The whole construction price is a sum of a door construction plus МАСКО door fill.</p>`
    },
    NEWS_POPUP: {
      MORE: 'read more'
    }
  },
  'ro-MD': {
    LANG_TITLE: 'Rom',
    INFO_TITLE: 'Informație',
    ERROR_TITLE: 'Eroare',
    STEP_NEXT: 'următ',
    STEP_BACK: 'înapoi',
    VIEW_OUTSIDE: 'externă',
    VIEW_INSIDE: 'interior',
    SELECT_DOOR_SERIES: 'Selectati seria de uși',
    NEWS: 'Știri',
    CLOSE_POPUP: 'Închide',
    MODEL: {
      NO_MODELS: 'Nu există modele din această serie.'
    },
    VALIDATE: {
      REQUIRED: 'Câmpul nu trebuie să fie gol.',
      EMAIL: 'E-mail invalid.',
      BETWEEN: 'De la ${min_value} la ${max_value}',
      FILE_SIZE:
        'Dimensiunea fișierului nu trebuie să depășească ${max_size} Мб',
      FILE_EXT: 'Extensia fișierului nu trebuie să fie ${ext}'
    },
    AUTH: {
      LOGIN: 'Autentificare',
      EMAIL: 'E-mail',
      PASSWORD: 'Parola'
    },
    OPTIONS: {
      CONSTRUCTION: 'Constructie',
      EMPTY_CONSTRUCTION: 'Fara constructii.',
      DIRECTION: 'Direcţie',
      SIZES: 'Dimensiuni',
      SIZES_SELECT: 'Selectați dimensiunea ușii',
      W: 'lăţime',
      H: 'înălţime',
      L: 'stânga',
      R: 'dreapta',
      MM: 'mm',
      FRAME: 'Cadru',
      HEIGHT: 'Înălţime',
      DESCRIPTION: `Dimensiunea maximă a panoului:<br/>
        896х2146 mm - HPL & PVC<br/>
        1200х2300 mm - Tot din aluminiu<br/>
        Dimensiunea maximă a ușii este limitată de dimensiunea panoului + lățimea sistemului de profil.`,
      MODEL_VIEW: 'Vedere model',
      INOX: 'Decor',
      SAFE_GLASS: 'Sticlă sigură'
    },
    MENU: {
      model: 'Model',
      colour: 'Culoare',
      options: 'Opțiuni',
      glass: 'Sticlă',
      furniture: 'Mobila',
      shape: 'Formă',
      house: 'Casa mea',
      config: 'Configurare',
      order: 'Ordin',
      my: 'Ușile mele'
    },
    MY: {
      NO_DOORS:
        'Nu ați adăugat încă uși. Accesați pagina „CONFIGURARE” și faceți clic pe „SALVAȚI CONFIGURAȚIA”.'
    },
    CONFIGURATOR: 'configurator',
    COLOUR: {
      NO_COLORS: 'Nu există.',
      BLACK: 'Design negru',
      TABS: ['HPL', 'Aluminium RAL']
    },
    FURNITURE: {
      SELECT_MODEL: 'Selectați un model.',
      EMPTY_FURNITURE: 'Nu există.'
    },
    SHARE: {
      COPY: 'Copie',
      COPY_LINK: 'copiați linkul',
      CODE: 'Cod de configurare',
      CODE_COPIED: 'Codul de configurare a fost copiat'
    },
    LOAD: {
      CODE: 'Cod de configurare',
      CANCEL: 'Anulare',
      LOAD: 'Deschideți',
      ERROR: 'Codul de configurare nu a fost găsit'
    },
    QUESTION: {
      PHONE: 'Numărul dvs. de telefon pentru feedback',
      PLACEHOLDER: 'Pune o intrebare',
      BTN_CANCEL: 'Anulare',
      BTN_SUBMIT: 'OK',
      ERROR: 'Eroare',
      THANKS: 'Multumesc pentru intrebare'
    },
    ORDER: {
      NAME: 'Nume',
      EDRPOU: 'Tax payer Code (if exists)',
      PHONE: 'Telefon',
      ADDRESS: 'Abordare',
      CITY: 'Adresa filialei Nova Post',
      ADDITIONAL: 'Informatii suplimentare',
      ADD_FILE: 'Adăugați fișier',
      CHOOSE_FILE: 'Alegeți fișier',
      SELECT_SIZE: 'Selectați dimensiunea panoului (exterior)',
      SELECT_BOTH_SIZE:
        'Panou suprapus pe două fețe <br/>Dimensiunea foii interioare',
      SELECT_WING: 'Panou deasupra capului unilateral',
      SELECT_WING_SIZE: 'Dimensiunea aripii',
      W: 'lăţime',
      H: 'înălţime',
      MM: 'mm',
      DESCRIPTION: ({ modelName, modelWidth, modelLength }) =>
        `Dimensiunea implicită a panoului este 900х2150 mm, dimensiunea minimă a modelului ${modelName}: lățime ${modelWidth}, lungime: ${modelLength}.`,
      MAX_SIZE: `Dimensiunea maximă a panoului:<br/>
        896 х 2146 mm - HPL & PVC<br/>
        1200 х 2300 mm - All aluminium.`,
      COMPLETE: 'Kit de configurare a panoului',
      CHECKOUT: 'Ordin'
    },
    SHAPE: {
      EMPTY_SHAPES: 'Nu există.'
    },
    GLASS: {
      SELECT_MODEL: 'Selectați un model.',
      NO_GLASSES: 'Nu există.'
    },
    CONFIG: {
      META: {
        TITLE: 'Configurare',
        DESCRIPTION: 'Configurare',
        OG_TITLE: 'Configurare',
        OG_DESCRIPTION: 'Configurare'
      },
      TITLE: 'Ordin',
      SAVE: 'Salvați configurația',
      DOWNLOAD: 'Descărcați specificații',
      CODE_SHARE: code =>
        `Codul ${code} este copiat în clipboard. Doar trimite-l managerului nostru pentru a calcula un preț.`,
      CODE_SHARE_ERROR:
        'Codul nu poate fi copiat din anumite motive. Vă rugăm să-l amintiți și să trimiteți direct managerului dvs',
      ADDED_TO_MY_DOORS: 'Adăugat la „Ușile mele”',
      TEXT: `<p>Partajați codul de configurare a ușii unui dealer sau producător.</p>
         <p>Acest configurator poate calcula doar un preț în funcție de umplerea ușii MACKO selectată.</p>
         <p>Întregul preț de construcție este o sumă a construcției unei uși plus МАСКО umplerea ușii.</p>`
    },
    NEWS_POPUP: {
      MORE: 'mai mult'
    }
  }
};
