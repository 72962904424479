<template>
  <button class="copy-to-clipboard-btn"
          :title="i18n.SHARE.COPY"
          @click="copyToClipboard(code)">
    {{i18n.SHARE.COPY_LINK}}
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['code'],
  computed: {
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  methods: {
    copyToClipboard(configCode) {
      // TODO: Check hash for router updates.
      let code = window.location.origin + '/#/model?code=' + configCode
      this.$copyText(code).then((e) => {
        this.$emit('success', e.text)
      }, () => {
        this.$emit('error', this.i18n.CONFIG.CODE_SHARE_ERROR)
      });
    },
  },
}
</script>

<style lang="scss">
.copy-to-clipboard-btn {
  font: 15px/18px 'ProximaNovaBold', sans-serif;
  color: #FFFFFF;
  height: 46px;
  background: #C70552 url(./copy.svg) no-repeat 13px/22px 22px;
  border: none;
  border-radius: 5px;
  width: 100%;
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
}
</style>
