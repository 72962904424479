<template>
  <strong class="logo">
    <a :href="logoHref">MACKO</a>
  </strong>
</template>

<style lang="scss">
  .logo {
    display: none;
    width: 100%;
    height: 90px;
    flex: 1 0 90px;
    background: #fff url(./logo.svg) 50%/186px 27px no-repeat;
    text-indent: -9999px;
    overflow: hidden;
    @include miw($xl) {
      display: block;
      max-width: 270px;
      flex: 1 0 270px;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>

<script>
import { LANGUAGE_CONFIG } from '@/constants/language'

export default {
  data() {
    return {
      lang: LANGUAGE_CONFIG,
    }
  },
  computed: {
    logoHref() {
      return this.lang.includes('uk-UA') ? 'https://macko.com.ua/' : 'https://macko.md/'
    }
  }
}
</script>
