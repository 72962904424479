<template>
  <svg:pattern :id="id"
           x="0"
           y="0"
           :width="doorBackgroundWidth"
           :height="doorBackgroundHeight"
           patternUnits="userSpaceOnUse">
    <svg:image x="0"
               y="0"
               ref="lamination"
               :href="doorBackgroundSrc"
               :width="doorBackgroundWidth"
               :height="doorBackgroundHeight" />
  </svg:pattern>
</template>

<script>
import { getBase64FromPNG } from '@/utils/picture'

export default {
  props: [
    'id',
    'doorBackgroundImage',
    'doorBackgroundWidth',
    'doorBackgroundHeight',
  ],
  data() {
    return {
      doorBackgroundSrc: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    }
  },
  methods: {
    async loadDoorBackground() {
      if (this.doorBackgroundImage === '') {
        console.warn('Lamination image is not loaded.')
        return
      }
      try {
        this.doorBackgroundSrc = await getBase64FromPNG({
          image: this.doorBackgroundImage,
          w: this.doorBackgroundWidth,
          h: this.doorBackgroundHeight,
        })
      } catch (e) {
        console.error('Error loading lamination', e)
      }
    },
  },
  beforeMount() {
    this.loadDoorBackground()
  },
}
</script>
