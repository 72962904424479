<template>
  <overlay>
    <modal-window>
      <a href="#"
         @click.prevent="$emit('close')"
         class="modal-window__close">x</a>
      <div class="row">
        <div class="col">
          <div class="hint">{{i18n.QUESTION.PLACEHOLDER}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <phone-mask-input
            v-model="phone"
            :placeholder="i18n.QUESTION.PHONE"
            autoDetectCountry
            showFlag
            wrapperClass="field--phone"
            inputClass="field"
            flagClass="field--flag"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <textarea type="text"
                    v-model="question"
                    class="text-field"
                    :placeholder="i18n.QUESTION.PLACEHOLDER"
                    cols="20"
                    rows="5"/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn--dark"
                  @click.prevent="$emit('close')">{{i18n.QUESTION.BTN_CANCEL}}</button>
        </div>
        <div class="col">
          <button class="btn"
                  @click.prevent="postQuestion"
                  :disabled="!isAbleToAsk">{{i18n.QUESTION.BTN_SUBMIT}}</button>
        </div>
      </div>
      <div class="row" v-if="showError">
        <div class="col">
          <div class="hint">{{showError}}</div>
        </div>
      </div>
    </modal-window>
  </overlay>
</template>

<script>
import Overlay from '@/components/shared/modal-overlay'
import ModalWindow from '@/components/shared/modal-window'
import PhoneMaskInput from  'vue-phone-mask-input'
import axios from 'axios'
import { API } from '@/constants/api'
import { mapGetters } from 'vuex'

export default {
  components: {
    Overlay,
    ModalWindow,
    PhoneMaskInput,
  },
  data() {
    return {
      phone: '',
      question: '',
      showError: false,
    }
  },
  computed: {
    isAbleToAsk() {
      return this.question.trim() !== ''
    },
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  methods: {
    postQuestion() {
      this.showError = false
      let { phone, question } = this
      axios.post(API.POST_QUESTION, {
        params: {
          phone,
          question,
        }
      })
        .then(async response => {
          if (response.data.success) {
            await this.$swal({
              title: response.data.success_text,
              width: 375,
              confirmButtonText: 'OK',
              confirmButtonColor: '#C70552',
              customClass: {
                header: 'sweet-modal__header',
                title: 'sweet-modal__title',
                popup: 'sweet-modal__popup',
                content: 'sweet-modal__content',
                actions: 'sweet-modal__actions',
                confirmButton: 'sweet-modal__ok-button',
              },
            })
            this.$emit('close')
          } else {
            this.showError = response.data.message
          }
        })
    },
  },
}
</script>
