<template>
<g>
  <svg:pattern
    id="custom-glass"
    patternUnits="userSpaceOnUse"
    x="0"
    y="0"
    :width="doorLeftWidth + doorWidth + doorRightWidth"
    :height="doorTopHeight + doorHeight"
  >
    <svg:image
      :x="customGlassX"
      :y="customGlassY"
      :href="doorGlassSrc"
      :width="142"
      :height="298"
    />
  </svg:pattern>
</g>
</template>

<script>
import {getBase64FromPNG} from '@/utils/picture'

export default {
  props: [
    'doorLeftWidth',
    'doorWidth',
    'doorRightWidth',
    'doorTopHeight',
    'doorHeight',
    'customGlassX',
    'customGlassY',
    'doorGlassImage',
    'doorGlassWidth',
    'doorGlassHeight',
  ],
  data() {
    return {
      doorGlassSrc: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    }
  },
  methods: {
    async loadDoorGlass() {
      try {
        this.doorGlassSrc = await getBase64FromPNG({
          image: this.doorGlassImage,
          w: this.doorGlassWidth,
          h: this.doorGlassHeight,
        })
      } catch (e) {
        //
      }
    },
  },
  mounted() {
    this.loadDoorGlass()
  },
}
</script>
