<template>
  <VueSlickCarousel class="news-listing" v-bind="slickSettings" v-if="newsList.length">
    <div class="news-listing__item"
         v-for="item in newsList"
         :key="item.id">
      <div class="news-listing__item-img">
        <a :href="item.href" target="_blank">
          <img :src="item.src"
               :alt="item.alt">
        </a>
      </div>
      <div class="news-listing__item-content">
        <h5 class="news-listing__item-title">
          <a :href="item.href" target="_blank">{{ item.headline }}</a>
        </h5>
        <div v-html="item.text" />
      </div>
      <div class="news-listing__item-btn">
        <a :href="item.href"
           target="_blank"
           class="btn">{{i18n.NEWS.MORE}}</a>
      </div>
    </div>
  </VueSlickCarousel>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      newsList: [],
      slickSettings: {
        "dots": true,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 800,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    }
  },
  async beforeCreate() {
    let newsResponse
    try {
      newsResponse = await this.$store.dispatch('news/GET_NEWS')
    } catch (e) {
      console.error(e)
    } finally {
      this.$cookies.set('last_news_post', newsResponse?.data?.timestamp)
      this.newsList = newsResponse?.data?.news || []
    }
  },
  components: {
    VueSlickCarousel,
  },
  computed: {
    ...mapGetters('session', [
      'i18n',
    ]),
  }
}
</script>
<style>
.news-listing {
  padding: 18px 6px;
  display: flex;
  flex-direction: column;
  row-gap: 46px;
  background-color: #ffffff;
}

.news-listing__item {
  flex-shrink: 0;
}

.news-listing__item-img a {
  display: block;
  width: 100%;
  height: 0;
  padding-top: calc(214/280 * 100%);
  overflow: hidden;
  position: relative;
}

.news-listing__item-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.news-listing__item>*:last-child,
.news-listing__item-content>*:last-child {
  margin-bottom: 0;
}

.news-listing__item-title {
  margin: 10px 0 0;
  font: 12px/14px 'ProximaNovaBold', sans-serif;
  color: #101010;
  text-transform: uppercase;
}

.news-listing__item-title a {
  color: #101010;
  text-decoration: none;
}

.news-listing__item-content {
  margin-bottom: 14px;
}

.news-listing__item-content p {
  margin: 0 0 20px;
}

.news-listing__item-btn .btn {
  height: auto;
  display: inline-block;
  line-height: 36px;
  width: auto;
}

@media only screen and (min-width: 1024px) {
  .news-listing {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 140px 30px;
  }

  .news-listing__item {
    flex-basis: calc(100%/3 - 30px);
    padding: 0 10px;
  }

  .news-listing__item-title {
    font-size: 16px;
    line-height: 22px;
  }

  .news-listing__item-content {
    margin-bottom: 17px;
  }

  .news-listing__item-content p {
    margin-bottom: 16px;
  }

}
</style>

<style lang="scss">
.news-popup {
  .slick-dots {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -32px;
    width: 100%;
    z-index: 2;
    li {
      width: auto;
      height: auto;
      padding: 4px;
      button {
        border: none;
        width: auto;
        height: auto;
        padding: 0;
        &:before {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          text-indent: -9999px;
          overflow: hidden;
        }
      }
    }
  }
  @media only screen and (min-width: 480px) {
    .slick-dots {
      transform: none;
      left: auto;
      width: auto;
      right: 10px;
    }
  }
  @media only screen and (min-width: 1024px) {
    .slick-dots {
      right: -5px;
    }
  }

  .slick-dots li button:before {
    background-color: rgba(241, 241, 241, 0.9);
  }

  .slick-dots li.slick-active button:before {
    background-color: #C70552;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    position: absolute;
    text-indent: -9999px;
    width: 58px;
    height: 16px;
    top: 50%;
    z-index: 3;
    background: none;
    background-size: 58px 16px;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 0;
    overflow: hidden;
  }
  @media only screen and (min-width: 480px) {
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      top: auto;
      bottom: -50px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      bottom: -50px;
      width: 201px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 201px 16px;
    }
  }

  .slick-arrow.slick-prev {
    left: -20px;
    background-image: url(~@/assets/images/news/promo-prev-sm.svg);
  }

  @media only screen and (min-width: 480px) {
    .slick-arrow.slick-prev {
      left: 10px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .slick-arrow.slick-prev {
      background-image: url(~@/assets/images/news/promo-prev.svg);
    }
  }

  .slick-arrow.slick-next {
    right: -15px;
    background-image: url(~@/assets/images/news/promo-next-sm.svg);
  }

  @media only screen and (min-width: 480px) {
    .slick-arrow.slick-next {
      right: auto;
      left: 100px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .slick-arrow.slick-next {
      left: 250px;
      background-image: url(~@/assets/images/news/promo-next.svg);
    }
  }
}
</style>
