import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/sweetalert.scss'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'

let GTagId

if (window.location.host === 'macko.md') {
  GTagId = 'G-B8MJD3EBTV'
} else {
  GTagId = 'G-MLV8G9LWY1'
}

Vue.config.productionTip = false

Vue.use(VueClipboard)
Vue.use(VueSweetalert2)
Vue.use(VueGtag, {
  config: { id: GTagId }
})
Vue.use(VueCookies, { expires: '30d'})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
