import {
  SET_LANGUAGES,
  SET_LANGUAGE,
  SET_CURRENCIES,
  SET_CURRENCY
} from "../actions/session";
import i18n from "@/constants/l12n";
import axios from "axios";
// import l12n from '@/constants/l12n'
import { CURRENCY_STORAGE_KEY } from "@/constants/currency";
import {
  // LANGUAGE_CONFIG,
  LANGUAGE_STORAGE_KEY
} from "@/constants/language";

const storedLang = localStorage.getItem(LANGUAGE_STORAGE_KEY);
let navLang; // = window.navigator.userLanguage || window.navigator.language || LANGUAGE_CONFIG[0]
/*
if (navLang.startsWith('en-')) {
  navLang = 'en-US'
}
if (navLang.startsWith('ru-')) {
  navLang = 'ru-RU'
}
if (!(navLang in l12n)) {
  if (window.location.host === 'macko.md') {
    navLang = 'ro-MD'
  } else if (window.location.host === 'app.macko.com.ua') {
    navLang = 'uk-UA'
  } else {
    navLang = LANGUAGE_CONFIG[0]
  }
}
*/
if (window.location.host === "macko.md") {
  navLang = "ro-MD";
}
if (
  window.location.host === "app.macko.com.ua" ||
  window.location.host === "macko-app.md-design.pw" ||
  window.location.host === "m-app.md-design.pw" ||
  window.location.host === "localhost:8080"
) {
  navLang = "uk-UA";
}

const state = {
  languages: [],
  lang: storedLang ? storedLang : navLang,
  i18n: storedLang ? i18n[storedLang] : i18n[navLang],
  currencies: [],
  currency: ""
};

const getters = {
  languages: state => state.languages,
  lang: state => state.lang,
  i18n: state => state.i18n,
  currencies: state => state.currencies,
  currency: state => state.currency
};

const actions = {
  [SET_LANGUAGES]: ({ commit }, languages) => {
    commit(SET_LANGUAGES, languages);
  },
  [SET_LANGUAGE]: ({ commit }, lang) => {
    commit(SET_LANGUAGE, lang);
  },
  [SET_CURRENCIES]: ({ commit }, currencies) => {
    commit(SET_CURRENCIES, currencies);
  },
  [SET_CURRENCY]: ({ commit }, currency) => {
    commit(SET_CURRENCY, currency);
  }
};

const mutations = {
  [SET_LANGUAGES]: (state, languages) => {
    state.languages = languages;
  },
  [SET_LANGUAGE]: (state, lang) => {
    state.lang = lang;
    state.i18n = i18n[lang];
    axios.interceptors.request.use(request => {
      request.headers["Accept-Language"] = lang;
      return request;
    });
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
  },
  [SET_CURRENCIES]: (state, currencies) => {
    state.currencies = currencies;
  },
  [SET_CURRENCY]: (state, currency) => {
    state.currency = currency;
    localStorage.setItem(CURRENCY_STORAGE_KEY, JSON.stringify(currency));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
