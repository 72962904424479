<template>
  <g id="Top-Frame"
     v-if="doorTopHeight !== 0">
    <rect id="Top-Frame"
          class="top-frame"
          x="0"
          y="0"
          :style="`fill: ${fill}`"
          :width="totalWidth"
          :height="doorTopHeight" />
    <rect id="Top-Glass"
          class="glass"
          filter="url(#inset-shadow)"
          :x="glassOffset"
          :y="glassOffset"
          :width="glassWidth"
          :height="glassHeight" />
  </g>
</template>

<script>
export default {
  props: [
    'totalWidth',
    'doorTopHeight',
    'fill',
  ],
  computed: {
    glassWidth() {
      return this.totalWidth - this.glassOffset * 2
    },
    glassHeight() {
      return this.doorTopHeight - this.glassOffset * 2
    },
  },
  data() {
    return {
      glassOffset: 8.5
    }
  },
}
</script>
