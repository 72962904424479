<template>
  <overlay>
    <modal-window>
      <a href="#"
         @click.prevent="$emit('close')"
         class="modal-window__close">x</a>
      <div class="row">
        <div class="col">
          <input type="text"
                 v-model="code"
                 class="field"
                 :placeholder="i18n.LOAD.CODE">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn--dark"
                  @click.prevent="$emit('close')">{{i18n.LOAD.CANCEL}}</button>
        </div>
        <div class="col">
          <button class="btn"
                  @click.prevent="loadCode"
                  :disabled="!isAbleToLoadCode">{{i18n.LOAD.LOAD}}</button>
        </div>
      </div>
      <div class="row" v-if="showError">
        <div class="col">
          <div class="hint">{{i18n.LOAD.ERROR}}</div>
        </div>
      </div>
    </modal-window>
  </overlay>
</template>

<script>
import Overlay from '@/components/shared/modal-overlay'
import ModalWindow from '@/components/shared/modal-window'
import axios from 'axios'
import { API } from '@/constants/api'
import { sanitizeCode } from '@/utils/code'
import { mapGetters } from 'vuex'

export default {
  components: {
    Overlay,
    ModalWindow,
  },
  data() {
    return {
      code: '',
      showError: false,
    }
  },
  computed: {
    isAbleToLoadCode() {
      return this.code.trim() !== ''
    },
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  methods: {
    loadCode() {
      let { code } = this
      code = sanitizeCode(code)
      axios.get(API.GET_CONFIG_FROM_CODE, {
        params: {
          code,
        }
      })
        .then(response => {
          if (response.data.shape) {
            this.$store.dispatch('config/CONFIG_SET_DEFAULTS', response.data)
            this.$emit('load', this.code)
            if (response.data.messages?.length) {
              this.$swal({
                title: this.i18n.INFO_TITLE || 'Info',
                html: response.data.messages.join('<br />'),
                width: 375,
                confirmButtonText: 'OK',
                confirmButtonColor: '#C70552',
                customClass: {
                  header: 'sweet-modal__header',
                  title: 'sweet-modal__title',
                  popup: 'sweet-modal__popup',
                  content: 'sweet-modal__content',
                  actions: 'sweet-modal__actions',
                  confirmButton: 'sweet-modal__ok-button',
                },
              })
            }
          } else {
            this.showError = true
          }
        })
    },
  },
}
</script>
