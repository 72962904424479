var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"configurator",style:(_vm.flipHouse)},[_c('div',{staticClass:"configurator__wrapper"},[_c('img',{staticClass:"configurator__img",attrs:{"src":_vm.house === 'outside'
          ? '../../cameraview/door_notree_and_windows.jpg'
          : '../../cameraview/interior.jpg',"alt":""}}),(_vm.model.id)?_c('door',{key:_vm.doorKey1,class:[
        'configurator__door',
        'configurator__door--outside',
        { 'configurator__door--hide': _vm.house === 'inside' },
        {
          'configurator__door--standard':
            _vm.mirrored.mirrored_type === 'standard'
        },
        {
          'configurator__door--mirror': _vm.mirrored.mirrored_type === 'mirrored'
        }
      ],style:({
        width: ((_vm.doorTotalWidth / 10) + "vw"),
        height: ((_vm.doorTotalHeight / 10) + "vw")
      }),attrs:{"id":"svgObject1","model":_vm.model.url,"handle":_vm.furniture.url,"double":_vm.shape.double_door,"totalWidth":_vm.doorTotalWidth,"totalHeight":_vm.doorTotalHeight,"doorWidth":_vm.doorWidth,"doorHeight":_vm.doorHeight,"doorLeftWidth":_vm.doorLeftWidth,"doorRightWidth":_vm.doorRightWidth,"doorTopHeight":_vm.doorTopHeight,"options":_vm.options.construction_type,"doorMirrorType":_vm.mirrored.mirrored_type,"doorViewType":_vm.view,"doorInoxType":_vm.inox,"doorStrokeColor":_vm.color[_vm.house].stroke,"doorSolidColor":_vm.color[_vm.house].colour_prevailing,"doorBackgroundImage":_vm.outdoorBackgroundImage,"handleColor":_vm.color[_vm.house].colour,"doorBackgroundWidth":_vm.color[_vm.house].pattern_width,"doorBackgroundHeight":_vm.color[_vm.house].pattern_height,"doorBackgroundColor":_vm.outdoorBackgroundColor,"doorGlassImage":_vm.glass.image_pattern,"customGlass":_vm.glass.repeat_pattern === '0',"doorGlassWidth":_vm.glass.pattern_width,"doorGlassHeight":_vm.glass.pattern_height,"doorGlassSandBlasting":_vm.glass.sandblasting,"doorInox":_vm.glass.inox,"houseView":_vm.house,"isBlack":_vm.isBlackOutside}}):_vm._e(),(_vm.model.id)?_c('door',{key:_vm.doorKey2,class:[
        'configurator__door',
        'configurator__door--inside',
        { 'configurator__door--hide': _vm.house === 'outside' },
        {
          'configurator__door--standard':
            _vm.mirrored.mirrored_type === 'standard'
        },
        {
          'configurator__door--mirror': _vm.mirrored.mirrored_type === 'mirrored'
        }
      ],style:({
        width: (((_vm.doorTotalWidth / 11) * 1.12) + "vw"),
        height: (((_vm.doorTotalHeight / 11) * 1.12) + "vw")
      }),attrs:{"id":"svgObject2","model":_vm.model.url,"handle":_vm.furnitureInside.url,"double":_vm.shape.double_door,"totalWidth":_vm.doorTotalWidth,"totalHeight":_vm.doorTotalHeight,"doorWidth":_vm.doorWidth,"doorHeight":_vm.doorHeight,"doorLeftWidth":_vm.doorLeftWidth,"doorRightWidth":_vm.doorRightWidth,"doorTopHeight":_vm.doorTopHeight,"options":_vm.options.construction_type,"doorMirrorType":_vm.mirrored.mirrored_type,"doorViewType":_vm.view,"doorInoxType":_vm.inox,"doorStrokeColor":_vm.color[_vm.house].stroke,"doorSolidColor":_vm.color[_vm.house].colour_prevailing,"doorBackgroundImage":_vm.indoorBackgroundImage,"handleColor":_vm.color[_vm.house].colour,"doorBackgroundWidth":_vm.color[_vm.house].pattern_width,"doorBackgroundHeight":_vm.color[_vm.house].pattern_height,"doorBackgroundColor":_vm.indoorBackgroundColor,"doorGlassImage":_vm.glass.image_pattern,"customGlass":_vm.glass.repeat_pattern === '0',"doorGlassWidth":_vm.glass.pattern_width,"doorGlassHeight":_vm.glass.pattern_height,"doorGlassSandBlasting":_vm.glass.sandblasting,"doorInox":_vm.glass.inox,"houseView":_vm.house,"isBlack":_vm.isBlackInside}}):_vm._e()],1),_c('price',{key:_vm.calcKey}),_c('transition',{attrs:{"name":"slide"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }