import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { ifAuthenticated, ifNotAuthenticated } from '@/utils/guard'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/shape',
    name: 'Shape',
    component: () => import('@/components/steps/shape.vue')
  },
  {
    path: '/model',
    name: 'Model',
    component: () => import('@/components/steps/model.vue')
  },
  {
    path: '/color',
    name: 'Color',
    component: () => import('@/components/steps/color.vue')
  },
  {
    path: '/glass',
    name: 'Glass',
    component: () => import('@/components/steps/glass.vue')
  },
  {
    path: '/furniture',
    name: 'Furniture',
    component: () => import('@/components/steps/furniture.vue')
  },
  {
    path: '/options',
    name: 'Options',
    component: () => import('@/components/steps/options.vue')
  },
  {
    path: '/house',
    name: 'House',
    component: () => import('@/components/steps/house.vue')
  },
  {
    path: '/config',
    name: 'Config',
    component: () => import('@/components/steps/config.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/components/steps/order.vue')
  },
  {
    path: '/my-door',
    name: 'MyDoor',
    component: () => import('@/components/steps/my-door.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/user/login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
