import {
  CONFIG_SET_DEFAULTS,
  CONFIG_SET_CODE,
  CONFIG_SET_SHAPE,
  CONFIG_SET_SERIES,
  CONFIG_SET_MODEL,
  CONFIG_SET_GLASS,
  CONFIG_SET_HOUSE_VIEW,
  CONFIG_SET_COLOR_TYPE,
  CONFIG_SET_COLOR_OUTSIDE,
  CONFIG_SET_COLOR_INSIDE,
  CONFIG_SET_FURNITURE,
  CONFIG_SET_CONSTRUCTION,
  CONFIG_SET_OPTIONS,
  CONFIG_SET_INOX,
  CONFIG_SET_VIEW,
  CONFIG_SET_MIRRORED,
  CONFIG_SET_SAFE_GLASS,
  CONFIG_ALLOW_CALCULATE,
  CONFIG_SET_WIDTH,
  CONFIG_SET_HEIGHT,
  CONFIG_SET_TOP_HEIGHT,
  CONFIG_SET_LEFT_WIDTH,
  CONFIG_SET_RIGHT_WIDTH,
  CONFIG_SET_BLACK,
  CONFIG_SET_FURNITURE_INSIDE
} from '../actions/config';

const state = {
  calculate: false,
  code: '',
  shape: {},
  series: '',
  model: {},
  glass: {},
  options: {},
  inox: {},
  house: 'outside',
  color: {
    type: 'complete',
    outside: {},
    inside: {}
  },
  view: {},
  mirrored: {},
  safeglass: {},
  furniture: {},
  furnitureInside: {},
  dimensions: {
    width: 1000,
    height: 2100,
    topHeight: 400,
    leftWidth: 400,
    rightWidth: 400
  },
  black: 'none'
};

const getters = {
  code: state => state.code,
  shape: state => state.shape,
  series: state => state.series,
  model: state => state.model,
  glass: state => state.glass,
  house: state => state.house,
  color: state => state.color,
  options: state => state.options,
  furniture: state => state.furniture,
  inox: state => state.inox,
  view: state => state.view,
  mirrored: state => state.mirrored,
  safeglass: state => state.safeglass,
  calculate: state => state.calculate,
  dimensions: state => state.dimensions,
  black: state => state.black,
  furnitureInside: state => state.furnitureInside
};

const actions = {
  [CONFIG_SET_DEFAULTS]: ({ dispatch }, defaults) => {
    dispatch('CONFIG_SET_SHAPE', defaults.shape);
    dispatch('CONFIG_SET_SERIES', defaults.model_category.url);
    dispatch('CONFIG_SET_MODEL', defaults.model);
    dispatch('CONFIG_SET_COLOR_OUTSIDE', defaults.color.outside);
    dispatch('CONFIG_SET_COLOR_INSIDE', defaults.color.inside);
    dispatch('CONFIG_SET_GLASS', defaults.glass);
    dispatch('CONFIG_SET_FURNITURE', defaults.furniture);
    if (defaults.options.konstruktsiya) {
      dispatch(
        'CONFIG_SET_CONSTRUCTION',
        defaults.options.konstruktsiya[0].construction_thikness[0]
      ); // TODO: refactor
    }
    dispatch('CONFIG_SET_INOX', defaults.inox);
    dispatch('CONFIG_SET_VIEW', defaults.view);
    dispatch('CONFIG_SET_MIRRORED', defaults.mirrored);
    dispatch('CONFIG_SET_SAFE_GLASS', defaults.safeglass);
    dispatch('CONFIG_SET_BLACK', defaults.inox.black);
  },
  [CONFIG_SET_CODE]: ({ commit }, code) => {
    commit(CONFIG_SET_CODE, code);
  },
  [CONFIG_SET_SHAPE]: ({ commit }, shape) => {
    commit(CONFIG_SET_SHAPE, shape);
  },
  [CONFIG_SET_SERIES]: ({ commit }, series) => {
    commit(CONFIG_SET_SERIES, series);
  },
  [CONFIG_SET_MODEL]: ({ commit }, model) => {
    commit(CONFIG_SET_MODEL, model);
  },
  [CONFIG_SET_GLASS]: ({ commit }, glass) => {
    commit(CONFIG_SET_GLASS, glass);
  },
  [CONFIG_SET_OPTIONS]: ({ commit }, options) => {
    commit(CONFIG_SET_OPTIONS, options);
  },
  [CONFIG_SET_FURNITURE]: ({ commit }, furniture) => {
    commit(CONFIG_SET_FURNITURE, furniture);
  },
  [CONFIG_SET_HOUSE_VIEW]: ({ commit }, houseView) => {
    commit(CONFIG_SET_HOUSE_VIEW, houseView);
  },
  [CONFIG_SET_COLOR_TYPE]: ({ commit }, type) => {
    commit(CONFIG_SET_COLOR_TYPE, type);
  },
  [CONFIG_SET_COLOR_OUTSIDE]: ({ commit }, color) => {
    commit(CONFIG_SET_COLOR_OUTSIDE, color);
  },
  [CONFIG_SET_COLOR_INSIDE]: ({ commit }, color) => {
    commit(CONFIG_SET_COLOR_INSIDE, color);
  },
  [CONFIG_SET_CONSTRUCTION]: ({ commit }, construction) => {
    commit(CONFIG_SET_CONSTRUCTION, construction);
  },
  [CONFIG_SET_INOX]: ({ commit }, inox) => {
    commit(CONFIG_SET_INOX, inox);
  },
  [CONFIG_SET_VIEW]: ({ commit }, view) => {
    commit(CONFIG_SET_VIEW, view);
  },
  [CONFIG_SET_MIRRORED]: ({ commit }, mirrored) => {
    commit(CONFIG_SET_MIRRORED, mirrored);
  },
  [CONFIG_SET_SAFE_GLASS]: ({ commit }, safeglass) => {
    commit(CONFIG_SET_SAFE_GLASS, safeglass);
  },
  [CONFIG_ALLOW_CALCULATE]: ({ commit }) => {
    commit(CONFIG_ALLOW_CALCULATE);
  },
  [CONFIG_SET_WIDTH]: ({ commit }, width) => {
    commit(CONFIG_SET_WIDTH, parseInt(width, 10));
  },
  [CONFIG_SET_HEIGHT]: ({ commit }, height) => {
    commit(CONFIG_SET_HEIGHT, parseInt(height, 10));
  },
  [CONFIG_SET_TOP_HEIGHT]: ({ commit }, height) => {
    commit(CONFIG_SET_TOP_HEIGHT, parseInt(height, 10));
  },
  [CONFIG_SET_LEFT_WIDTH]: ({ commit }, width) => {
    commit(CONFIG_SET_LEFT_WIDTH, parseInt(width, 10));
  },
  [CONFIG_SET_RIGHT_WIDTH]: ({ commit }, width) => {
    commit(CONFIG_SET_RIGHT_WIDTH, parseInt(width, 10));
  },
  [CONFIG_SET_BLACK]: ({ commit }, black) => {
    commit(CONFIG_SET_BLACK, black);
  },
  [CONFIG_SET_FURNITURE_INSIDE]: ({ commit }, furniture) => {
    commit(CONFIG_SET_FURNITURE_INSIDE, furniture);
  }
};

const mutations = {
  [CONFIG_SET_CODE]: (state, code) => {
    state.code = code;
  },
  [CONFIG_SET_SHAPE]: (state, shape) => {
    state.shape = shape;
  },
  [CONFIG_SET_SERIES]: (state, series) => {
    state.series = series;
  },
  [CONFIG_SET_MODEL]: (state, model) => {
    state.model = model;
  },
  [CONFIG_SET_GLASS]: (state, glass) => {
    state.glass = glass;
  },
  [CONFIG_SET_OPTIONS]: (state, options) => {
    state.options = options;
  },
  [CONFIG_SET_FURNITURE]: (state, furniture) => {
    state.furniture = furniture;
  },
  [CONFIG_SET_HOUSE_VIEW]: (state, house) => {
    state.house = house;
  },
  [CONFIG_SET_COLOR_TYPE]: (state, type) => {
    state.color.type = type;
  },
  [CONFIG_SET_COLOR_OUTSIDE]: (state, item) => {
    state.color.outside = item;
  },
  [CONFIG_SET_COLOR_INSIDE]: (state, item) => {
    state.color.inside = item;
  },
  [CONFIG_SET_CONSTRUCTION]: (state, construction) => {
    state.options = construction;
  },
  [CONFIG_SET_INOX]: (state, inox) => {
    state.inox = inox;
  },
  [CONFIG_SET_VIEW]: (state, view) => {
    state.view = view;
  },
  [CONFIG_SET_MIRRORED]: (state, mirrored) => {
    state.mirrored = mirrored;
  },
  [CONFIG_SET_SAFE_GLASS]: (state, safeglass) => {
    state.safeglass = safeglass;
  },
  [CONFIG_ALLOW_CALCULATE]: state => {
    state.calculate = true;
  },
  [CONFIG_SET_WIDTH]: (state, width) => {
    state.dimensions.width = width;
  },
  [CONFIG_SET_HEIGHT]: (state, height) => {
    state.dimensions.height = height;
  },
  [CONFIG_SET_TOP_HEIGHT]: (state, height) => {
    state.dimensions.topHeight = height;
  },
  [CONFIG_SET_LEFT_WIDTH]: (state, width) => {
    state.dimensions.leftWidth = width;
  },
  [CONFIG_SET_RIGHT_WIDTH]: (state, width) => {
    state.dimensions.rightWidth = width;
  },
  [CONFIG_SET_BLACK]: (state, black) => {
    state.black = black;
  },
  [CONFIG_SET_FURNITURE_INSIDE]: (state, furnitureInside) => {
    state.furnitureInside = furnitureInside;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
