import {
  AUTH_REQUEST,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  AUTH_ERROR,
} from '@/store/actions/auth'
import axios from 'axios'
import { API } from '@/constants/api'

const state = {
  status: '',
  token: localStorage.getItem('user-token') || ''
}

const getters = {
  authStatus: state => state.status,
  isAuthenticated: state => !!state.token,
}

const actions = {
  [AUTH_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      axios({
        url: API.AUTH,
        data,
        method: 'post',
      })
        .then(response => {
          const token = response.data.jwt
          if (token) {
            localStorage.setItem('user-token', token)
            commit(AUTH_SUCCESS, response)
          } else {
            reject(response.data.message)
          }
          resolve(response)
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          localStorage.removeItem('user-token')
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('user-token')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, res) => {
    state.status = 'success'
    state.token = res.data.jwt
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.token = ''
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
